
					@import './src/sass/variables';
					@import './src/sass/mixins';
				




































































































































































































































































































audio {
	outline: none;

	&::-webkit-media-controls-panel {
		background-color: $color__white;
	}
}

.audio-background {
	position: relative;
	height: 80px;
	background-color: #222831;
	border-radius: $border_radius;

	.progress {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 0;
		background-color: rgba($color: $color__light_blue, $alpha: 0.25);
		transition: width .3s linear;
	}

	canvas {
		width: 100%;
		height: 100%;
	}

	button {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 50%);
		border-radius: 50%;
		background-color: $color__light_blue;
		color: $color__white;
		padding: 14px;
		border: 3px solid $color__background;
		transition: background-color .2s ease;
		outline: none;

		&:hover {
			background-color: $color__blue;
		}

		&.playing {
			background-color: $color__red;

			&:hover {
				background-color: darken($color__red, 10%);
			}
		}

		svg {
			display: block;
		}
	}
}

.meta {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}

.audio-background,
.meta {
	max-width: 600px;
}
